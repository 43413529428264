import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from './Footer'

function PrivacyPolicy() {
  return (
    <div>
         <Navbar/>
        <div className='privacy-cotainer'>  <h1 className='prv-header'>ICADPAY PRIVACY POLICY</h1>
        
            <ul className='intro'>
                <li>
                    <h4>Introduction</h4>
                    <p>IcadPay and its affiliates (“IcadPay”, “we”, “our” or “us”) takes the privacy and protection of personal information seriously. By visiting our website or using our services, you acknowledge and accept the practices and policies outlined in this Privacy Policy (the “Privacy Policy”). This Privacy Policy applies to all information collected from or about you by IcadPay. The purpose of this document is to help you understand what information is collected from or about you, how this information is used and protected, and what options you have with regard to this information.</p>
                </li>
                <li>
                    <h4>Information Covered by this Policy</h4>
                    <p>For purposes of this Privacy Policy, our use of the term “Information” means any and all information we receive from or about you. This may include (without limitation) information collected from you directly or indirectly, manually or otherwise, or via electronic, automatic or other means, including (without limitation) via stationary, mobile or any other devices. Such information may include information (i) which you provide during the course of your use of our website (the “Site”), or your use of our services or our platform (the “Services”); (ii) collected about you, your business, and the technology you are using, and (iii) which we receive about you from third parties or other sources.</p>
                    <p>Information is divided into two distinct categories:</p>

                    <ul className='sub-info'>
                        <li>“Non-Personal Information” means Information which does not personally identify a person.</li>
                        <li>“Personal Information” means Information related to a natural person that can be used to directly or indirectly identify the person, such as names, addresses, e-mail addresses, and phone numbers, as well as other non-public information that is associated with the foregoing.</li>
                    </ul>
                </li>
                <li>
                    <h4>Acceptance of Terms</h4>
                    <p>We obtain and use Information in accordance with this Privacy Policy. We will ask for your consent before using Personal Information in a manner other than as set out in this Privacy Policy.</p>

                    <h3 className='by-using'>BY USING OUR SERVICES OR VISITING OUR SITE OR SUBMITTING INFORMATION THROUGH THE SERVICES OR VIA OUR SITE, YOU EXPRESSLY CONSENT TO THE COLLECTION, PROCESSING, AND USE OF YOUR INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY, INCLUDING THE COLLECTION, USE, DISCLOSURE, STORAGE, TRANSFER AND PROCESSING OF YOUR INFORMATION, INCLUDING YOUR PERSONAL INFORMATION, AS DESCRIBED HEREIN. YOUR INFORMATION, INCLUDING YOUR PERSONAL IDENTIFIABLE INFORMATION, MAY BE PROCESSED IN THE COUNTRY WHERE IT WAS COLLECTED AS WELL AS IN OTHER COUNTRIES WHERE LAWS REGARDING THE PROCESSING OF INFORMATION, INCLUDING PERSONAL INFORMATION, MAY BE LESS STRINGENT THAN THE LAWS IN YOUR COUNTRY.</h3>
                    <h3>IF YOU DO NOT AGREE WITH OUR PRIVACY POLICY, PLEASE DO NOT USE OUR SITE, PLATFORM, OR SERVICES.</h3>
                </li>
                <li>
                    <h4>How we Obtain and Collect Your Personal Information</h4>
                    <p>You may provide us with your personal information voluntarily. However, we may also receive personal information about you from third parties such as marketing agencies, market research companies, our suppliers, contractors and consultants of our Affiliates, your colleagues and business contacts, public websites, and public agencies.</p>
                    <p>We collect Personal Information that you give us by filling in forms, using our Services, contacting us, setting up an account with us, or by contacting us by phone, email or other means; and that we learn about you from our business interactions, communications, and dealings with you.</p>
                    <p>We also collect Personal Information you communicate to other users of the Services or the Site through the Services or the Site. However, we are under no obligation to review, and assumes no responsibility or liability relating to, such communications.</p>
                    <p>We also collect Personal Information automatically when we receive and store certain types of information automatically when you interact with the Sites or use the Services.</p>
                </li>
                <li>
                    <h4>Information We Collect</h4>
                    <p>We collect the following personal information:</p>
                    <ul className='sub-info'>
                        <li>When the organization that you represent becomes a customer and/or you become a user of our Services, we may collect the following types of information: your first and last names; gender; date of birth; government-issued identification and/or number; photo identification; address; fax; country of residence; nationality; place of birth; country of birth; your company and/or personal email address; your telephone number; your company name; your job title; your company’s industry, trade details and regulated status; general information about your interest in our products and services; information provided when you correspond with us; any updates to information provided to us; information you provide to help us provide you with improved services; information about your marketing preferences, and personal information we collect about you or that we obtain from our third party sources.</li>
                        <li>Whenever you contact us or respond to our communications (e.g., in email, telephone, or writing), we receive your contact information and any other personal information you choose to provide us. We will use such information to fulfil your requests or to provide services.</li>
                        <li>We collect Personal Information when you use or request information about our Services, subscribe to marketing communications, request support, complete surveys, or sign up for and/or attend events we participate in.</li>
                        <li>Your communications with other users of the Services or the Site may be monitored, recorded and/or reviewed by IcadPay.
                            <p>In addition, the following information is created and recorded manually or automatically as the case may be when you visit our Site or use our Services</p>
                        </li>
                        <li>Technical information, including the Internet protocol (IP) address used to connect your computer to the internet address; the website address and country from which you access information; the files requested; browser type and version; browser plug-in types and versions; operating system; and platform. We use this personal information to administer our Site, to measure the efficiency of our systems and to undertake an analysis on the locations from which people access our webpages; and</li>
                        <li id='cookies'>Information about your visit and your behaviour on our Site or our Services. This may include the website you visit before and after visiting our Site (including date and time), time and length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, traffic data, location data, weblogs and other communication data and information provided when requesting further service or downloads.</li>

                    </ul>
                </li>
                <li>
                    <h4>Cookies</h4>
                    <p>We, and third parties we may partner with, may store and retrieve information on and from your browser by placing a small text file called a “Cookie” in the browser files of your computer, smartphone, or other device. By using the Services or the Site, you agree that we may use, store, and access Cookies on and from your computer, smartphone, or other device. Always take your time to read more on the information about Cookies and how we or our partner use them when it pops up when you engage our site.</p>

                </li>
                <li>
                    <h4>Non-Personal Information</h4>
                    <p>Non-Personal Information may be collected, processed, and used in accordance with applicable law, for any and all purposes we deem fit. Generally, the information IcadPay collects is used only for our own internal purposes, for example, to improve the Services and the Site, to improve our marketing and promotional efforts, to improve customer service, and to improve IcadPay content and Site offerings, and may also be used by IcadPay to contact you to provide you with information which we believe may be useful to you. IcadPay believes that these uses allow it to improve the Services and our Site and to better tailor them to meet customers’ needs.</p>

                </li>
                <li>
                    <h4>Use of Personal Information</h4>
                    <p>In general, Personal Information you submit to us, or that is obtained by us, is used:</p>
                    <ul className='in-general'>
                        <li>to create and manage the business relationship,</li>
                        <li>to provide and support our services to the organization you represent,</li>
                        <li>to improve our Services,</li>
                        <li>o communicate with you about our Services,</li>
                        <li>to send you notifications regarding your use of the Services or the Site,</li>
                        <li>to send you notifications by various means, including regular mail, email, telephone,including voicemail, or SMS (text message)</li>
                        <li>to respond to requests that you make,</li>
                        <li>to bill you, to resolve fee disputes and for other associated billing services,</li>
                        <li>to further new services or products,</li>
                        <li>to operate, maintain, and improve the features, functionality, and performance of our Site and Services,</li>
                        <li>to comply with our legal obligations;</li>
                        <li>to comply with our regulatory obligations;</li>
                        <li>to monitor compliance with our rulebooks and contractual terms of use;</li>
                        <li>to better understand our customers and the way they use and interact with our Site and our Services;</li>
                        <li>to enhance security, prevent fraud or market abuse, monitor and verify identity or service access, combat spam or other malware or security risks;</li>
                        <li>to deliver targeted marketing and service update notices;</li>
                        <li>to facilitate events we participate in and communicate with you about those;</li>
                        <li>to enforce our agreements with third parties;</li>
                        <li>to protect our business and to enforce and exercise our rights.
                            <p>Furthermore, we may create anonymized information from Personal Information by excluding information that makes the information personally identifiable to you. We may use this anonymized information for internal purposes, such as analyzing usage patterns, so that we may enhance the Site and Services, and we also reserve the right to use and disclose such anonymized information at our discretion to third parties. Such anonymized information may be deemed to be Non-Personal Information and used accordingly.</p>
                        </li>
                        
                    </ul>
                </li>
                <li>
                    <h4>Disclosure of Personal Information</h4>
                    <p>Except as otherwise stated in this Privacy Policy, we will not disclose or share your Personal Information with third parties, unless you ask or authorize us to do so.</p>
                    <p>We disclose your Personal Information to our subsidiaries, joint ventures, or other companies under common control (collectively, “Affiliates”), in which case we will require our Affiliates to honor this Privacy Policy. We may provide your Personal Information to your employer and to your colleagues within the organization that you represent. We may provide your Personal Information to any legal, regulatory or governmental body that we are required to disclose information to, as well as to credit control and debt collection agencies. In addition, we may provide your Personal Information to business partners, third party service providers, and suppliers who work on our behalf (a) to provide you with some of the Services or the Site, (b) to operate, improve, understand, customize, and support the Services or the Site, (c) to help us communicate with our customers, (d) to assist us in our marketing, advertising and promotional activities (e) to analytics and search engine providers that assist us in the improvement and optimization of our Services or our Site and (f) to help us with our business and informational needs. However, we do not grant these business partners, service providers, and suppliers any independent right to use this information except to help us provide the Services, operate the Site, or for our legitimate business interests.</p>
                    <p>In the event IcadPay goes through a business transition such as a merger, acquisition by another company, or sale of all or a portion of its assets, your Personal Information will likely be among the assets transferred. You acknowledge that such transfers may occur, and that any acquirer of IcadPay, or a portion or all of its assets may continue to use your Personal Information as set forth in this Privacy Policy.</p>
                    <p>We may disclose Personal Information we have collected from and about you if we believe in good faith that such disclosure is necessary (a) to comply with relevant laws, (b) to respond to court orders, subpoenas, or warrants served on us, (c) to respond to an emergency, or (d) to protect and defend IcadPay’s rights or property.</p>
                    <p>Please note that third parties you interact with, including your employer, may have their own privacy policies, and IcadPay is not responsible for their operations, including, but not limited to, their personal information practices. Personal Information collected by third parties, which may include contact details, is governed by their privacy practices. We encourage you to learn about the privacy practices of those third parties.</p>
                </li>
                <li>
                    <h4>Retention of Your Personal Information</h4>
                    <p>We keep your Personal Information for as long as is necessary for the purposes for which the Personal Information is processed. The length of time for which we retain Personal Information depends on the purposes for which we collect and use it and/or as required to comply with applicable laws and to establish, exercise or defend our legal rights.</p>
                </li>
                <li>
                    <h4>Data Transfers</h4>
                    <p>We may transfer the Information, including Personal Information, we collect about you to recipients in countries other than the country in which the information originally was collected, including but not exclusively, countries outside Africa, Asia, America or European. Those countries may not have the same data protection laws as the country in which you initially provided the information. By using or participating in any of our Services or the Site, and/or by providing us with your Information, you consent to the collection, transfer, storage and processing of your Information in such other countries, including countries within and outside of Nigeria. In those cases where we transfer Personal Information collect in Nigeria to Affiliates or to our service providers located in countries outside Nigeria, we will take appropriate measures to ensure that the recipient protects your Personal Information adequately in accordance with this Privacy Policy. These measures may include entering into Nigerian Data Protection Act contractual arrangements designed to ensure that your Personal Information is protected.</p>
                </li>
                <li>
                <h4>Legal Bases for Our Use of Your Personal Information</h4>
                <p>We rely on both your consent and our legitimate business interests as the legal bases for processing Personal Information.</p>
                <p>In this regard, we use your Personal Information when necessary (a) to perform our obligations under any contract with you; (b) to comply with our legal obligations (for example, complying with our legal and regulatory obligations); and (c) for our legitimate interests or the legitimate interests of others (for example, to ensure the security of our Site or Services). If we rely on our (or another person’s) legitimate interests for using your Personal Information, we will undertake a balancing test to ensure that our (or the other person’s) legitimate interests are not outweighed by your interests or fundamental rights and freedoms which require protection of the Personal Information.</p>
                <p>If we rely on your consent for us to use your Personal Information in a particular way, but you later change your mind, you may withdraw your consent by contacting us at the e-mail address set forth in the “Contact Us” section below, and we will stop doing so.</p>
                </li>
                <li>
                    <h4>Links to Other Websites</h4>
                    <p>The Services and/or the Site may contain links to other websites that are not owned or controlled by us. The provision of such links does not signify our endorsement of such websites or their content. We have no control over, do not review and are not responsible for, the privacy policies, terms of use, or content displayed on such other websites. Please be aware that the terms of our Privacy Policy do not apply to any outside websites.</p>
                </li>
                <li>
                    <h4>Security and Confidentiality</h4>
                    <p>IcadPay has put in place commercially reasonable protections and procedures to safeguard and secure the Personal Information we collect. However, even with these measures, we cannot guarantee the security of Personal Information. By using our Services or our Site, you acknowledge and agree that we make no such guaranty.</p>
                </li>
                <li>
                    <h4>Access to, Correcting or Deleting Your Personal Information</h4>
                    <p>If we hold Personal Information about you, you may request that we correct, amend, or delete certain information where it is inaccurate, within reason and in accordance with applicable laws. Prior to fulfilling any request for information or deletion, we may require you to provide information so that we may verify your identity. Subject to applicable laws, we reserve the right to decline requests that are unreasonable, present risk to the privacy of another individual, may jeopardize the confidentiality of another party, are excessively repetitive, may require significant technical modifications to our systems. Please contact us at the address set forth in the “Contact Us” section below if you need assistance in updating or reviewing your information.</p>
                </li>
                <li>
                    <h4>Your Rights with Respect to Your Personal Information</h4>
                    <p>Nigeria Data Protection Act makes a distinction between organizations that process personal information for their own purposes (known as “Data Controllers”) and organizations that process personal information on behalf of other organizations (known as “Data Processors”).</p>
                    <p>In the limited circumstances where we act as a Data Controller, Nigerian citizens have certain data protection rights, including:</p>

                    <ul className='your-rights'>
                        <li>the right to access your Personal Information;</li>
                        <li>the right to restrict the use of your Personal Information;</li>
                        <li>the right to have incomplete or inaccurate data corrected;</li>
                        <li>the right to ask us to stop processing your Personal Information; and</li>
                        <li>the right to require us to delete your Personal Information in some limited circumstances.</li>
                        <li>the right in some circumstances to request for us to “port” your personal data in a portable, re-usable format to other organizations (where this is possible).</li>
                    </ul>
                    <p>We will consider all such requests and provide our response within a reasonable period (and in any event within one month of your request unless we tell you we are entitled to a longer period allowed by applicable law). Please note, however, that certain Personal Information may be exempt from such requests in certain circumstances, for example if we need to keep using the information to comply with our own legal obligations or to establish, exercise or defend legal claims. If an exception applies, we will tell you this when responding to your request. We may request you provide us with information necessary to confirm your identity before responding to any request you make.</p>
                    <h4>Export or erase personal data</h4>
                    <p>If you filled a form on this website you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal or security purposes.</p>
                </li>
                <li>
                    <h4>Marketing Activities</h4>
                    <p>We may collect and use your personal information for undertaking marketing by email telephone and mail. We may send you certain marketing communications (including electronic marketing communications to existing customers) if it is in our legitimate interests to do so for marketing and business development purposes.</p>
                    <p>We will always obtain your consent to direct marketing communications where we are required to do so by law (for example, if you have provided a personal email address to us) and if we intend to disclose your personal information to any third party for such marketing.</p>
                    <p>If you wish to stop receiving marketing communications, you can contact us at the address set forth in the “Contact Us” section below.</p>
                </li>
                <li>
                    <h4>Changes to this Privacy Policy</h4>
                    <p>We may change this Privacy Policy at any time by posting a new version on this page or on a successor page. The new version will become effective on the date it is posted. We reserve the right to modify this Privacy Policy at any time, and you agree that your continued use of our Services or our Site shall constitute your acceptance of any modifications to such Privacy Policy, so please review it frequently.</p>
                </li>
                <li className='contact-us'>
                    <h4>Contact Us and Opting-Out</h4>
                    <p>If you wish to exercise any of your rights in relation to your Personal Information, including exercising your opt-out rights, please send us an email to our Compliance Officer at <a href="enquiries@icadpay.com"> enquiries@icadpay.com</a>.</p>
                    <p>If you have any questions regarding our Privacy Policy or the collection or use of your Information, please contact our Compliance Officer at <a href="enquiries@icadpay.com">enquiries@icadpay.com</a>.</p>
                </li>
                
               
            </ul>

        </div>
            <Footer/>
    </div>
  )
}

export default PrivacyPolicy